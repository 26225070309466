import {
	ChangeDetectorRef,
	Component,
	Inject,
	OnDestroy,
	OnInit,
	PLATFORM_ID,
	Signal,
	computed,
	inject,
	signal,
} from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { ToolbarService } from './services/toolbar/toolbar.service';
import { Subscription, filter, startWith } from 'rxjs';
import { AuthService } from './services/auth/auth.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ProgramStoreService } from './services/stores/program-store.service';
import { UserStoreService } from './services/stores/user-store.service';
import { UserDataModel } from './models/user.model';
import { CanonicalLinkService } from './services/canonical-link/canonical-link.service';
import { CreatePlaylistService } from './services/create-playlist/create-playlist.service';
import { Animations } from './animations/animations';
import { environment } from '../environments/environment';
import { ThemeService } from '../libs/theme/theme.service';
import { isPlatformBrowser } from '@angular/common';

declare const gtag: Function; // <------------Important: the declartion for gtag is required!

export interface IUserInfo {
	isAuthenticated: boolean;
	isInstructor: boolean;
	isProgramAdmin: boolean;
	isProgramManager: boolean;
	isAdministrator: boolean;
	hasActiveSubscription: boolean;
	hasActiveTrialSubscription: boolean;
	hasActiveSubscriptionOrTrial: boolean;
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [Animations.page],
    standalone: false
})
export class AppComponent implements OnInit, OnDestroy {
	private _themeService = inject(ThemeService);
	public theme$ = this._themeService.theme$;
	isLoading$ = signal(false);
	hasLoadingError$ = signal(false);
	mobileQuery: MediaQueryList;
	sidenavStateSubscription = {} as Subscription;
	sidenavShowing: boolean = false;
	private userInfoSubscription = {} as Subscription;
	userInfo$ = {} as Signal<IUserInfo>;

	constructor(
		private authService: AuthService,
		private router: Router,
		private programStoreService: ProgramStoreService,
		private userStoreService: UserStoreService,
		private toolbarService: ToolbarService,
		private createPlaylistService: CreatePlaylistService,
		changeDetectorRef: ChangeDetectorRef,
		media: MediaMatcher,
		private canonicalLinkService: CanonicalLinkService,
		private activatedRoute: ActivatedRoute,
		@Inject(PLATFORM_ID) private platformId: Object,
	) {
		this.isLoading$.set(true);
		this.mobileQuery = media.matchMedia('(max-width: 600px)');
		this._mobileQueryListener = () => changeDetectorRef.detectChanges();
		this.mobileQuery.addListener(this._mobileQueryListener);
		this.sidenavStateSubscription = toolbarService.currentSidenavState$.subscribe((state) => {
			this.sidenavStateChanged(state);
		});

		this.userInfo$ = computed(() => {
			return {
				isAuthenticated: false,
				isInstructor: false,
				isProgramAdmin: false,
				isProgramManager: false,
				isAdministrator: false,
				hasActiveSubscription: false,
				hasActiveTrialSubscription: false,
				hasActiveSubscriptionOrTrial: false,
			};
		});
	}

	async ngOnInit(): Promise<void> {
		this.setUpAnalytics();

		this.isLoading$.set(true);

		await this.userStoreService.initialize();

		this.userInfo$ = computed(() => {
			return {
				isAuthenticated: this.userStoreService.userData$()?.isAuthenticated() === true,
				isInstructor: this.userStoreService.userData$().hasInstructorRole(),
				isProgramAdmin: this.userStoreService.userData$().hasProgramAdminRole(),
				isProgramManager: this.userStoreService.userData$().hasProgramManagerRole(),
				isAdministrator: this.userStoreService.userData$().hasSystemAdminRole(),
				hasActiveSubscription: this.userStoreService.userData$()?.hasActiveSubscription(),
				hasActiveTrialSubscription: this.userStoreService.userData$()?.hasActiveTrialSubscription(),
				hasActiveSubscriptionOrTrial: this.userStoreService.userData$()?.hasActiveSubscriptionOrTrial(),
			};
		});

		this.userInfoSubscription = this.userStoreService.userDataStateChanged$.subscribe((data) => {
			this.handleUserDataChange(data);
		});

		const hostName = `${environment.websiteUrl}`;
		// set up the canonical with an initial value
		this.canonicalLinkService.updateCanonicalUrl(hostName);

		// subscribe to changes in the route so that each change should update
		// the canonical if the route has a canonical property
		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				startWith(this.router),
			)
			.subscribe(() => {
				if (this.activatedRoute.children.length > 0) {
					this.activatedRoute.children[0].data?.subscribe((data) => {
						// Set Canonical URL if data is present
						if (data && data['canonical']) {
							this.canonicalLinkService.updateCanonicalUrl(hostName + data['canonical']);
						} else {
							this.canonicalLinkService.updateCanonicalUrl(hostName);
						}
					});
				}
			});
	}

	ngOnDestroy(): void {
		this.mobileQuery.removeListener(this._mobileQueryListener);
		this.sidenavStateSubscription.unsubscribe();
	}

	handleUserDataChange(userDataModel: UserDataModel) {
		if (
			userDataModel.isAuthenticated() &&
			(userDataModel.hasInstructorRole() ||
				userDataModel.hasProgramAdminRole() ||
				userDataModel.hasProgramManagerRole() ||
				userDataModel.hasSystemAdminRole())
		) {
			Promise.all([this.programStoreService.initialize()])
				.then(() => {
					this.isLoading$.set(false);
				})
				.catch((e: any) => {
					console.error('error', e);
					this.hasLoadingError$.set(true);
				});
		} else {
			this.isLoading$.set(false);
		}
	}

	logout() {
		this.authService.logout();
	}

	public isDarkMode(): boolean {
		let currentTheme = '';
		this.theme$.subscribe((mode) => {
			currentTheme = mode;
		});
		return currentTheme === 'dark';
	}

	public toggleTheme(): void {
		this._themeService.toggleDarkMode();
	}

	private _mobileQueryListener: () => void;

	sidenavClicked(): void {
		this.toolbarService.toggleSidenav();
	}

	sidenavStateChanged(currentValue: boolean): void {
		this.sidenavShowing = currentValue;
	}

	async createPlaylist(): Promise<void> {
		await this.createPlaylistService.createPlaylist();
	}

	setUpAnalytics() {
		if (isPlatformBrowser(this.platformId)) {
			this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((incomingEvent: any) => {
				const event = incomingEvent as NavigationEnd;
				gtag('event', 'page_view', {
					page_path: event.urlAfterRedirects,
				});
			});
		}
	}

	getHomeRouterLink(): string {
		if (this.userStoreService.userData$()?.hasInstructorRole()) {
			return '/instructor/home';
		}

		return '/home';
	}

	isLinkActive(link: string): boolean {
		return this.router.url.includes(link);
	}
}
