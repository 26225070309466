import { Component, OnInit, Signal, computed } from '@angular/core';
import { IUserInfo } from '../../app.component';
import { UserStoreService } from '../../services/stores/user-store.service';
import { Router } from '@angular/router';
import { CreatePlaylistService } from '../../services/create-playlist/create-playlist.service';
import { Animations } from '../../animations/animations';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    animations: [Animations.page],
    standalone: false
})
export class FooterComponent implements OnInit {
	userInfo$ = {} as Signal<IUserInfo>;
	marketingSiteUrl: string;

	constructor(
		private createPlaylistService: CreatePlaylistService,
		private userStoreService: UserStoreService,
		private router: Router,
	) {
		this.marketingSiteUrl = environment.marketingSiteUrl;
		this.userInfo$ = computed(() => {
			return {
				isAuthenticated: false,
				isInstructor: false,
				isProgramAdmin: false,
				isProgramManager: false,
				isAdministrator: false,
				hasActiveSubscription: false,
				hasActiveTrialSubscription: false,
				hasActiveSubscriptionOrTrial: false,
			};
		});
	}
	async ngOnInit(): Promise<void> {
		this.userInfo$ = computed(() => {
			return {
				isAuthenticated: this.userStoreService.userData$()?.isAuthenticated() === true,
				isInstructor: this.userStoreService.userData$().hasInstructorRole(),
				isProgramAdmin: this.userStoreService.userData$().hasProgramAdminRole(),
				isProgramManager: this.userStoreService.userData$().hasProgramManagerRole(),
				isAdministrator: this.userStoreService.userData$().hasSystemAdminRole(),
				hasActiveSubscription: this.userStoreService.userData$()?.hasActiveSubscription(),
				hasActiveTrialSubscription: this.userStoreService.userData$()?.hasActiveTrialSubscription(),
				hasActiveSubscriptionOrTrial: this.userStoreService.userData$()?.hasActiveSubscriptionOrTrial(),
			};
		});
	}

	async createPlaylist(): Promise<void> {
		await this.createPlaylistService.createPlaylist();
	}

	getHomeRouterLink(): string {
		if (this.userStoreService.userData$()?.hasInstructorRole()) {
			return '/instructor/home';
		}

		return '/home';
	}

	isLinkActive(link: string): boolean {
		return this.router.url.includes(link);
	}
}
