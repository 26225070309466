import { NgModule, inject, provideAppInitializer } from '@angular/core';

import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginInterceptor } from './interceptors/login.interceptor';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { AppModule } from './app.module';
import { provideClientHydration } from '@angular/platform-browser';
import { NEWRELIC_CONFIGURATION, NewrelicService } from './services/newrelic/newrelic.service';
import { environment } from '../environments/environment';
import { ClientScrollService } from './services/scroll/client.scroll.service';
import { SCROLL_SERVICE } from './services/scroll/scroll.interface.service';

@NgModule({
	imports: [AppModule],
	providers: [
		ClientScrollService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpErrorInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: LoginInterceptor,
			multi: true,
		},
		{
			provide: NEWRELIC_CONFIGURATION,
			useValue: {
				isEnabled: environment.newrelic.isEnabled,
				accountId: environment.newrelic.accountId,
				trustKey: environment.newrelic.trustKey,
				agentId: environment.newrelic.agentId,
				applicationId: environment.newrelic.applicationId,
				licenseKey: environment.newrelic.licenseKey,
				assetDomain: environment.newrelic.assetDomain,
				beaconDomain: environment.newrelic.beaconDomain,
			},
		},
		provideAppInitializer(async () => {
			const newrelicService = inject(NewrelicService);
		}),
		{
			provide: SCROLL_SERVICE,
			useClass: ClientScrollService,
		},
		provideClientHydration(),
	],
	bootstrap: [AppComponent],
})
export class AppClientModule {}
