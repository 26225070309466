import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';

@Component({
    selector: 'app-account-removed',
    templateUrl: './account-removed.component.html',
    styleUrls: ['./account-removed.component.scss'],
    standalone: false
})
export class AccountRemovedComponent {
	constructor(private authService: AuthService) {}

	returnToHome(): void {
		this.authService.logout();
	}
}
