import { Component, OnInit, Signal, computed, inject } from '@angular/core';
import { IUserInfo } from '../../app.component';
import { UserStoreService } from '../../services/stores/user-store.service';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ThemeService } from '../../../libs/theme/theme.service';
import { MatDividerModule } from '@angular/material/divider';
import { AuthService } from '../../services/auth/auth.service';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-menu',
    templateUrl: './app-menu.component.html',
    styleUrls: ['./app-menu.component.scss'],
    imports: [MatDividerModule, MatIconModule, MatButtonModule, MatMenuModule, MatSlideToggleModule, RouterModule]
})
export class AppMenuComponent implements OnInit {
	private _themeService = inject(ThemeService);
	public theme$ = this._themeService.theme$;
	userInfo$ = {} as Signal<IUserInfo>;
	marketingSiteUrl = environment.marketingSiteUrl;

	constructor(
		private authService: AuthService,
		private userStoreService: UserStoreService,
	) {
		this.userInfo$ = computed(() => {
			return {
				isAuthenticated: false,
				isInstructor: false,
				isProgramAdmin: false,
				isProgramManager: false,
				isAdministrator: false,
				hasActiveSubscription: false,
				hasActiveTrialSubscription: false,
				hasActiveSubscriptionOrTrial: false,
			};
		});
	}
	async ngOnInit(): Promise<void> {
		this.userInfo$ = computed(() => {
			return {
				isAuthenticated: this.userStoreService.userData$()?.isAuthenticated() === true,
				isInstructor: this.userStoreService.userData$().hasInstructorRole(),
				isProgramAdmin: this.userStoreService.userData$().hasProgramAdminRole(),
				isProgramManager: this.userStoreService.userData$().hasProgramManagerRole(),
				isAdministrator: this.userStoreService.userData$().hasSystemAdminRole(),
				hasActiveSubscription: this.userStoreService.userData$()?.hasActiveSubscription(),
				hasActiveTrialSubscription: this.userStoreService.userData$()?.hasActiveTrialSubscription(),
				hasActiveSubscriptionOrTrial: this.userStoreService.userData$()?.hasActiveSubscriptionOrTrial(),
			};
		});
	}

	public isDarkMode(): boolean {
		let currentTheme = '';
		this.theme$.subscribe((mode) => {
			currentTheme = mode;
		});
		return currentTheme === 'dark';
	}

	public toggleTheme(): void {
		this._themeService.toggleDarkMode();
	}

	getHomeRouterLink(): string {
		if (this.userInfo$().isInstructor) {
			return '/instructor/home';
		}

		return '/home';
	}

	logout(): void {
		this.authService.logout();
	}
}
