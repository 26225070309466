import { Component } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { UserStoreService } from '../../services/stores/user-store.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: false
})
export class LoginComponent {
	constructor(
		private authService: AuthService,
		private activatedRoute: ActivatedRoute,
		private userStoreService: UserStoreService,
	) {}
	returnUrl: string | null = null;
	requestPending = false;
	marketingSiteUrl = environment.marketingSiteUrl;

	async ngOnInit(): Promise<void> {
		this.requestPending = false;
		const queryParams = this.activatedRoute.snapshot.queryParams;
		if (queryParams['returnUrl']) {
			this.returnUrl = queryParams['returnUrl'];
		}

		const userData = this.userStoreService.userData$();
		if (userData.isAuthenticated()) {
			this.redirectAuthenticatedUser();
		}
	}

	login() {
		this.requestPending = true;
		this.authService.login(this.returnUrl ? this.returnUrl : `${environment.websiteUrl}/instructor/home`);
	}

	createAccount() {
		this.requestPending = true;
		this.authService.createNewAccount(`${environment.websiteUrl}/create-account`);
	}

	redirectAuthenticatedUser() {
		window.location.href = this.returnUrl ? this.returnUrl : `${environment.websiteUrl}/instructor/home`;
	}
}
