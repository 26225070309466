import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export interface FruitReadModel {
	id: string;
	fruitName: string;
	selected: boolean;
}

@Component({
    selector: 'app-autocomplete-multi',
    templateUrl: './autocomplete-multi.component.html',
    styleUrls: ['./autocomplete-multi.component.scss'],
    standalone: false
})
export class AutocompleteMultiComponent {
	separatorKeysCodes: number[] = [ENTER, COMMA];
	fruitCtrl = new FormControl('');
	filteredFruits: Observable<FruitReadModel[]>;
	allFruits: FruitReadModel[] = [
		{ id: '1', fruitName: 'Apple', selected: false },
		{ id: '2', fruitName: 'Lemon', selected: false },
		{ id: '3', fruitName: 'Lime', selected: false },
		{ id: '4', fruitName: 'Orange', selected: false },
		{ id: '5', fruitName: 'Strawberry', selected: false },
	];
	fruits: FruitReadModel[] = [];

	@ViewChild('fruitInput') fruitInput = {} as ElementRef<HTMLInputElement>;

	constructor() {
		this.filteredFruits = this.fruitCtrl.valueChanges.pipe(
			startWith(null),
			map((fruitText: string | null) => (fruitText ? this._filter(fruitText) : this.allFruits.slice()))
		);
	}

	remove(fruit: FruitReadModel): void {
		const index = this.fruits.indexOf(fruit);
		fruit.selected = false;

		if (index >= 0) {
			this.fruits.splice(index, 1);
		}
	}

	selected(event: MatAutocompleteSelectedEvent): void {
		const fruit = event.option.value;
		fruit.selected = true;
		this.fruits.push(event.option.value);
		this.fruitInput.nativeElement.value = '';
		this.fruitCtrl.setValue(null);
		this.filteredFruits = this.fruitCtrl.valueChanges.pipe(
			startWith(null),
			map((fruitText: string | null) => (fruitText ? this._filter(fruitText) : this.allFruits.slice()))
		);
	}

	checkFruit($event: MouseEvent, fruit: FruitReadModel) {
		$event.stopPropagation();
		this.toggleSelection(fruit);
	}

	toggleSelection(fruit: FruitReadModel) {
		console.log('toggleSelection', fruit);
		fruit.selected = !fruit.selected;
		if (fruit.selected) {
			this.fruits.push(fruit);
		} else {
			const i = this.fruits.findIndex((value) => value.id === fruit.id);
			this.fruits.splice(i, 1);
		}
		// this.userControl.setValue(this.selectedUsers);
	}

	private _filter(value: string): FruitReadModel[] {
		const filterValue = value.toLowerCase();

		return this.allFruits.filter((fruit) => fruit.fruitName.toLowerCase().includes(filterValue));
	}
}
