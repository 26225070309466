import { Component, ElementRef, OnInit, Signal, ViewChild, computed, signal } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { ToolbarService } from '../../services/toolbar/toolbar.service';
import { UserStoreService } from '../../services/stores/user-store.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { AppMenuComponent } from '../app-menu/app-menu.component';

interface IUserInfo {
	isInitializing: boolean;
	isAuthenticated: boolean;
}

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    imports: [AppMenuComponent, MatButtonModule, MatIconModule, MatMenuModule, MatToolbarModule, RouterModule]
})
export class HeaderComponent implements OnInit {
	constructor(
		private authService: AuthService,
		private userStoreService: UserStoreService,
		private toolbarService: ToolbarService,
		private router: Router,
	) {}

	userInfo$ = {} as Signal<IUserInfo | null>;
	@ViewChild('searchInput') searchInput = {} as ElementRef;
	isOnLoginPage$ = signal(false);

	ngOnInit(): void {
		this.router.events.subscribe(() => {
			this.isOnLoginPage$.set(this.router.url.includes('/login'));
		});

		this.userInfo$ = computed(() => {
			return {
				isInitializing: this.userStoreService.isInitializing$(),
				isAuthenticated: this.userStoreService.userData$()?.isAuthenticated() === true,
			};
		});
	}

	logout(): void {
		this.authService.logout();
	}

	toggleSidenav(): void {
		this.toolbarService.toggleSidenav();
	}

	performHomeAction(): void {
		if (this.userInfo$()?.isAuthenticated) {
			this.router.navigate(['/instructor/home']);
		} else {
			this.router.navigate(['/']);
		}
	}

	getUserProfilePictureThumbnailUrl(): string {
		let url = this.userStoreService.userData$()?.getUserData()?.getInstructorProfileThumnbnailUrl();
		if (url) {
			return url;
		}

		return '/assets/images/gfx-heartbeat.webp';
	}
}
